<script>
export default {
    name: 'SelphidComponent'
  }

//import { useRouter } from 'vue-router';

//const router = useRouter();

/*const onExtractionFinished = (event) => {
    console.log('[SELPHI] detail', event.detail.detail);
    router.push('/selphi');
}*/

</script>

<template>
    <facephi-selphid-widget
     :bundlePath="bundlePath"
     showLog="true"
     allowUncertain="true"
     @extractionFinish="onExtractionFinished"
    >
    </facephi-selphid-widget>
</template>