// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import HelloWorld from '@/components/HelloWorld.vue';
import SelphiComponent from '@/components/SelphiComponent.vue';
import SelphidComponent from '@/components/SelphidComponent.vue';
import FinishedComponent from '@/components/FinishedComponent.vue';
import StartComponent from '@/components/StartComponent.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: HelloWorld
    },
    {
        path: '/home',
        component: HelloWorld
    },
    {
        path: '/selphi',
        component: SelphiComponent,
        name: 'selphi',
        props: true
    },
    {
        path: '/selphid',
        component: SelphidComponent,
        name: 'selphid',
        props: true
    },
    {
        path: '/finish',
        component: FinishedComponent,
        name: 'finish',
        props: true
    },
    {
        path: '/start',
        component: StartComponent,
        name: 'start',
        props: true
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
