<template>
  <div id="app">
    <Navbar />
    <router-view />
  </div>

  <HelloWorld msg="Facephi testing"/>
  <div class="start-component">
      <h2>Tarea iniciada!!!</h2>
  </div>
  <div><a href="/home">Home</a></div>
  <div><a href="/start">Start</a></div>
  <div><a href="/selphi">Selphi</a></div>
  <div><a href="/selphid">Selphid</a></div>
  <div><a href="/finish">Finalizado</a></div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue';
import '@facephi/selphi-widget-web';
import '@facephi/selphid-widget-web';
import { defineCustomElements } from '@facephi/sdk-web-wc/loader';


/*const emitOperationId = (event) => {
  console.log('Operation ID:', event.detail)
}

const emitSessionId = (event) => {
  console.log('Session ID:', event.detail)
}

const emitExtraData = (event) => {
  console.log('ExtraData:', event.detail)
}*/

defineCustomElements(window);

export default {
  name: 'App',
  components: {
    HelloWorld,
  },
  methods: {
      emitOperationId(event) {
        console.log('OperationId: ', event.details);
      },
      emitSessionId(event) {
        console.log('SessionId: ', event.details);
      },
      emitExtraData(event) {
        console.log('ExtraData: ', event.details);
      }
    }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>