<template>
    <div class="start-component">
      <h2>Tarea iniciada!!!</h2>
    </div>
    <facephi-sdk-provider
        apikey="HWxm4BNoAg1HzgYKegI6E39mx4dQ6U6Dqms2xR7v"
        steps="START,SELPHI_WIDGET,SELPHID_WIDGET,FINISH"
        customer-id="vue-example"
        type="ONBOARDING"
        bundle-path="/assets/widgets"
        @emitOperationId="emitOperationId"
        @emitSessionId="emitSessionId"
        @emitExtraData="emitExtraData"
        >
            <div class="container">
                <RouterView />
            </div>
    </facephi-sdk-provider>
  </template>
  
  <script>
  export default {
    name: 'StartComponent',
    methods: {
      emitOperationId(event) {
        console.log('OperationId: ', event.details);
      },
      emitSessionId(event) {
        console.log('SessionId: ', event.details);
      },
      emitExtraData(event) {
        console.log('ExtraData: ', event.details);
      }
    }
  }
  </script>
  
  <style scoped>
  .start-component {
    text-align: center;
    color: #333;
  }
  </style>