<template>
    <facephi-selphi-widget
     bundlePath="/src/assets/widgets/selphi"
     @extractionFinish="onExtractionFinished"
    >
    </facephi-selphi-widget>
</template>

<script>
//import { useRouter } from 'vue-router';

export default {
    name: 'SelphiComponent',
    methods: {
        onExtractionFinished(event) {
            console.log('[SELPHI] detail', event.detail.detail);
            console.log('[SELPHI] img', event.detail.detail.bestImage.currentSrc);
        }
    }
}

/*const router = useRouter();

const onExtractionFinished = (event) => {
    console.log('[SELPHI] detail', event.detail.detail);
    console.log('[SELPHI] img', event.detail.detail.bestImage.currentSrc);
    router.push('/finish');
}*/

</script>