<template>
    <div class="finish-component">
      <h2>Tarea finalizada!!!</h2>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FinishedComponent'
  }
  </script>
  
  <style scoped>
  .finish-component {
    text-align: center;
    color: #333;
  }
  </style>